import React, { Component } from 'react';

class Navbar extends Component {
  render() {
    return (
      <nav className="bg-brown fixed w-full z-10">
        <div className="mx-auto px-2 sm:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <div
                  className="title cursor-pointer font-pixelcraft"
                  style={{ fontSize: '24px' }}
                >
                  Wolf Game
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
