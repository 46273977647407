import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '../ui/button/Button';

class Confirm extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            className="relative flex justify-center w-full h-full p-1 overflow-hidden md:p-5"
            style={{
              borderImage: 'url("https://wolf.game/images/wood-frame.svg") 30 / 1 / 0 stretch',
              borderWidth: '30px',
              background: 'rgb(237, 227, 209)',
            }}
          >
            <div
              className="absolute"
              style={{
                width: '120%',
                height: '120%',
                top: '-20px',
                left: '-20px',
                opacity: '0.04',
                backgroundImage: 'url("https://wolf.game/images/wood-mask.svg")',
                backgroundRepeat: 'repeat',
                backgroundSize: '400px 268px',
              }}
            ></div>
            <div className="relative w-full h-full z-index:5">
              {this.props.title && (
                <div className="mt-4 font-console text-3xl text-center text-red">{this.props.title}</div>
              )}
              <div className="font-console mb-4 px-2 text-lg max-w-screen-sm text-center mt-4 text-red">
                {this.props.message}
              </div>
              <div className="flex flex-col-reverse items-center md:flex-row md:justify-center">
                <div className="mx-2 my-2 md:my-0">
                  <Button onClick={this.props.handleAgree}>{this.props.notify ? 'OK' : 'Proceed'}</Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Confirm;
