import { dummyTx } from '../utils/dummyTx';

export const depositDemo = {
  maxDeposit: 1017,
  depositHistory: [
    {
      tx: dummyTx(),
      amount: 500,
      status: 'Done',
    },
    {
      tx: dummyTx(),
      amount: 400,
      status: 'Done',
    },
    {
      tx: dummyTx(),
      amount: 1000,
      status: 'Done',
    },
  ],
};

export const withdrawDemo = {
  maxWithdraw: 460,
};
