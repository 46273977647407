export default function Button(props) {
  const clickHandler = (event) => {
    !props.disabled && props.onClick && props.onClick(event);
  };

  return (
    <div
      onClick={clickHandler}
      className={
        'relative flex items-center justify-center ' +
        (props.disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer')
      }
      style={{
        width: '220px',
        borderImage: 'url("https://wolf.game/images/wood-frame.svg") 5 / 1 / 0 stretch',
        borderWidth: '10px',
      }}
    >
      <div className="text-center font-console pt-1">{props.children}</div>
    </div>
  );
}
