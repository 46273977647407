import React, { Component } from 'react';
import Spinner from '../ui/spinner/Spinner';
import Button from '../ui/button/Button';
import Confirm from './Confirm';
import { withdrawDemo } from '../demo/data';

class Withdrawal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputAmount: 0,
      finalAmount: 0,
      buttonNumber: 0,
      isLoading: false,
      isStartDisabled: false,
      isInitDisabled: true,
      isFinishDisabled: true,
      confirmOpen: false,
      confirmMessage: '',
      notifyOpen: false,
      notifyMessage: '',
    };

    this.onClickButton = this.onClickButton.bind(this);
  }

  handleChange = (event) => {
    if (this.validateAmount(event.target.value)) {
      this.setState({ inputAmount: event.target.value });
    } else {
      this.setState({
        notifyMessage: `You cannot withdraw more than ${withdrawDemo.maxWithdraw} WOOL`,
        notifyOpen: true,
      });
    }
  };

  handleConfirmClose = () => {
    this.setState({ confirmOpen: false });
  };

  handleNotifyClose = () => {
    this.setState({ notifyOpen: false });
    setTimeout(() => this.setState({ notifyMessage: '' }), 500);
  };

  validateAmount = (value) => {
    const amount = (value && value) || this.state.inputAmount;
    return amount <= withdrawDemo.maxWithdraw && (value ? amount >= 0 : amount > 0) && amount % 1 === 0;
  };

  onClickButton = (numberButton) => {
    if (numberButton === 1) {
      if (this.validateAmount()) {
        this.setState({
          confirmOpen: true,
          confirmMessage:
            'This choice cannot be reversed without completing withdrawal and then re-depositing later',
        });
      } else {
        this.setState({
          notifyMessage: `Please enter a valid amount`,
          notifyOpen: true,
        });
      }

      this.setState({ finalAmount: this.state.inputAmount });
      this.setState({ buttonNumber: 1 });
    } else if (numberButton === 2) {
      this.setState({
        confirmOpen: true,
        confirmMessage: 'This is the first of two blockchain transactions that will be required to withdraw.',
      });
      this.setState({ buttonNumber: 2 });
    } else if (numberButton === 3) {
      this.setState({ buttonNumber: 3 });
      this.setState({
        confirmOpen: true,
        confirmMessage: 'Withdrawing WOOL requires a transaction to mint your WOOL on the blockchain.',
      });
    }
  };

  onAgree = () => {
    if (this.state.buttonNumber === 1) {
      this.setState({ isInitDisabled: false, isStartDisabled: true, confirmOpen: false });
    } else if (this.state.buttonNumber === 2) {
      this.setState({ isInitDisabled: true, confirmOpen: false, isLoading: true });
      setTimeout(() => {
        this.setState({ isLoading: false, isFinishDisabled: false });
      }, 10000);
    } else if (this.state.buttonNumber === 3) {
      this.setState({
        isFinishDisabled: true,
        confirmOpen: false,
        notifyMessage: `You have successfully withdrawn your WOOL`,
        notifyOpen: true,
      });
      withdrawDemo.maxWithdraw -= this.state.finalAmount;
      this.setState({
        isStartDisabled: false,
        isInitDisabled: true,
        isFinishDisabled: true,
        buttonNumber: 0,
        finalAmount: 0,
        inputAmount: 0,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({ confirmOpen: true });
  };

  onRadioClick = () => {
    this.setState({ inputAmount: withdrawDemo.maxWithdraw });
  };

  render() {
    return (
      <div>
        <div className="w-full flex flex-col items-center">
          <div className="text-xl text-red max-w-lg text-center mt-8">
            For security, a 72 hour delay and two blockchain transactions are required on withdrawals.
          </div>
        </div>
        <form className="flex flex-col justify-center items-center mt-4">
          <div>
            <input
              className={
                'text-center text-2xl w-64 mt-1 disabled:bg-silver ' +
                (this.state.isStartDisabled ? 'cursor-not-allowed' : 'cursor-text')
              }
              type="number"
              value={this.state.inputAmount || 0}
              onChange={this.handleChange}
              disabled={this.state.isStartDisabled}
            />{' '}
          </div>
          <div className="my-3">
            <div
              className={
                'max-radio ' + (this.state.isStartDisabled ? 'cursor-not-allowed disabled' : 'cursor-pointer')
              }
              onClick={!this.state.isStartDisabled ? this.onRadioClick : undefined}
            >
              Max: {withdrawDemo.maxWithdraw}
            </div>
          </div>
          {!this.state.isLoading && !this.state.isFinishDisabled && (
            <>
              <h2 className="text-2xl text-red max-w-lg text-center mt-8 mb-4">Ready for Final Withdrawal</h2>
              <Button
                disabled={this.state.isFinishDisabled}
                onClick={this.onClickButton.bind(this, 3)}
              >
                Withdraw {this.state.finalAmount} WOOL
              </Button>
            </>
          )}
          {this.state.isLoading ? (
            <div>
              <h2 className="text-2xl text-red max-w-lg text-center mt-8">
                Pending Withdrawal: {this.state.finalAmount} WOOL
              </h2>
              <Spinner />
            </div>
          ) : (
            <>
              {this.state.isFinishDisabled && (
                <div>
                  <Button
                    disabled={this.state.isStartDisabled}
                    onClick={this.onClickButton.bind(this, 1)}
                  >
                    Start Withdrawal
                  </Button>
                </div>
              )}

              {this.state.isStartDisabled && this.state.isFinishDisabled && (
                <div className="mt-4">
                  <Button
                    disabled={this.state.isInitDisabled}
                    onClick={this.onClickButton.bind(this, 2)}
                  >
                    Initiate on Blockchain
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
        <Confirm
          open={this.state.confirmOpen}
          handleAgree={this.onAgree}
          handleClose={this.handleConfirmClose}
          message={this.state.confirmMessage}
        />
        <Confirm
          notify
          open={this.state.notifyOpen}
          handleAgree={this.handleNotifyClose}
          handleClose={this.handleNotifyClose}
          onClose={this.handleNotifyClose}
          message={this.state.notifyMessage}
        />
      </div>
    );
  }
}

export default Withdrawal;
