import React, { Component } from 'react';
import Tabs from '../ui/tabs/Tabs';
import Deposit from './Deposit';
import Withdrawal from './Withdrawal';

class WoolContainer extends Component {
  render() {
    return (
      <div
        className="flex flex-col items-center w-full font-console"
        style={{ overflow: 'hidden scroll' }}
      >
        <div className="flex flex-col items-center w-full p-5">
          <div className="title text-center justify-self-start mb-5 font-pixelcraft">WOOL</div>
          <div className="max-w-screen-md w-full">
            <div
              className="relative flex justify-center w-full h-full p-1 overflow-hidden md:p-5"
              style={{
                borderImage: 'url("https://wolf.game/images/wood-frame.svg") 30 / 1 / 0 stretch',
                borderWidth: '30px',
                background: 'rgb(237, 227, 209)',
              }}
            >
              <div
                className="absolute"
                style={{
                  width: '120%',
                  height: '120%',
                  top: '-20px',
                  left: '-20px',
                  opacity: '0.04',
                  backgroundImage: 'url("https://wolf.game/images/wood-mask.svg")',
                  backgroundRepeat: 'repeat',
                  backgroundSize: '400px 268px',
                }}
              ></div>
              <div className="relative w-full h-full z-index:5">
                <div className="m-4">
                  <Tabs>
                    <div label="Deposit">
                      <Deposit />
                    </div>
                    <div label="Withdrawal">
                      <Withdrawal />
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WoolContainer;
