import Navbar from './components/Nav';
import WoolContainer from './components/Wool';

function App() {
  return (
    <>
      <Navbar />
      <main className="min-h-[100vh] px-4 pt-24 pb-16 sm:px-6 sm:pb-20 lg:px-8 xl:px-10 xl:pb-24 3xl:px-12">
        <WoolContainer />
      </main>
    </>
  );
}

export default App;
