import React, { Component } from 'react';
import Confirm from './Confirm';
import Button from '../ui/button/Button';
import { depositDemo } from '../demo/data';
import { truncate } from '../utils/truncate';
import { dummyTx } from '../utils/dummyTx';

class Deposit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
      notifyOpen: false,
      notifyMessage: '',
      disabled: false,
      depositValue: 0,
      depositHistory: depositDemo.depositHistory,
    };
  }

  handleConfirmClose = () => {
    this.setState({ confirmOpen: false });
  };

  handleNotifyClose = () => {
    this.setState({ notifyOpen: false });
    setTimeout(() => this.setState({ notifyMessage: '' }), 500);
  };

  handleClickOpen = () => {
    if (
      this.state.depositValue > 0 &&
      this.state.depositValue <= depositDemo.maxDeposit &&
      this.state.depositValue % 1 === 0
    ) {
      this.setState({ confirmOpen: true });
    } else {
      this.setState({ notifyMessage: 'Please enter a valid amount', notifyOpen: true });
    }
  };

  handleChange = (event) => {
    if (
      event.target.value <= depositDemo.maxDeposit &&
      event.target.value >= 0 &&
      event.target.value % 1 === 0
    ) {
      this.setState({ depositValue: event.target.value });
    } else {
      this.setState({
        notifyMessage: `You cannot deposit more than ${depositDemo.maxDeposit} WOOL`,
        notifyOpen: true,
      });
    }
  };

  handleSubmit = (event) => {
    let depositValue = this.state.depositValue;
    this.setState({
      confirmOpen: false,
      disabled: true,
      depositHistory: [
        {
          tx: dummyTx(),
          amount: depositValue,
          status: 'PENDING',
        },
        ...this.state.depositHistory,
      ],
    });

    this.setState({ depositValue: 0 });
    setTimeout(() => {
      const depHistory = this.state.depositHistory.slice();
      depHistory[0].status = 'Done';
      this.setState({ depositHistory: depHistory, disabled: false });
      depositDemo.maxDeposit -= depositValue;
    }, 3000);
    event.preventDefault();
  };

  onRadioClick = () => {
    this.setState({ depositValue: depositDemo.maxDeposit });
  };

  render() {
    return (
      <div className="w-full flex flex-col items-center">
        <div className="text-xl text-red max-w-lg text-center mt-8">Deposit WOOL to Your In-Game Wallet</div>
        <form className="flex flex-col justify-center items-center mt-4">
          <div>
            <input
              className={
                'text-center text-2xl w-64 mt-1 disabled:bg-silver ' +
                (this.state.depositHistory[0].status == 'PENDING' ? 'cursor-not-allowed' : 'cursor-text')
              }
              type="number"
              value={this.state.depositValue}
              onChange={this.handleChange}
              disabled={this.state.depositHistory[0].status == 'PENDING'}
            />{' '}
          </div>
          <div className="my-3">
            <div
              className={
                'max-radio ' +
                (this.state.depositHistory[0].status == 'PENDING'
                  ? 'cursor-not-allowed disabled'
                  : 'cursor-pointer')
              }
              onClick={this.state.depositHistory[0].status == 'PENDING' ? undefined : this.onRadioClick}
            >
              Max: {depositDemo.maxDeposit}
            </div>
          </div>
          <Button
            onClick={this.handleClickOpen}
            disabled={this.state.disabled}
          >
            Deposit
          </Button>
        </form>

        <div className="w-full mx-auto overflow-x-auto xl:flex xl:flex-col xl:items-center">
          <div className="text-xl text-red text-center mt-10 mb-2">Deposit History</div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr className="text-2xl border-b-4 border-brown">
                <th
                  className="whitespace-nowrap text-center text-xl"
                  style={{ width: '50%' }}
                >
                  TX
                </th>
                <th
                  className="whitespace-nowrap text-center text-xl"
                  style={{ width: '50%' }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.depositHistory.map((item) => (
                <tr
                  className="text-xl text-center"
                  key={item.tx}
                >
                  <td className="py-2">
                    <a
                      href={`https://etherscan.io/tx/${item.tx}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {truncate(item.tx, 10)}
                    </a>
                  </td>
                  {item.status === 'PENDING' ? (
                    <td>PENDING - {item.amount} WOOL</td>
                  ) : (
                    <td>{item.amount} WOOL</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Confirm
          open={this.state.confirmOpen}
          handleAgree={this.handleSubmit}
          handleClose={this.handleConfirmClose}
          onClose={this.handleConfirmClose}
          message="Depositing WOOL requires a transaction to burn your WOOL on the blockchain. WOOL can be withdrawn and re-minted at any time."
        />
        <Confirm
          notify
          open={this.state.notifyOpen}
          handleAgree={this.handleNotifyClose}
          handleClose={this.handleNotifyClose}
          onClose={this.handleNotifyClose}
          message={this.state.notifyMessage}
        />
      </div>
    );
  }
}

export default Deposit;
